var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{attrs:{"name":"Auth"}},[_c('div',[_c('h1',{staticClass:"text-ag mt-10 mb-6"},[_c('span',{staticClass:"highlighted"},[_vm._v("Zarejestruj")]),_vm._v(" się")]),_c('p',{staticClass:"mb-10 text-h6"},[_vm._v("Dostęp możliwy tylko za indywidualnym zaproszeniem")]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.step == 'loading')?[_c('div',{staticClass:"py-8 text-center"},[_c('PpSpinner',{attrs:{"size":"32","color":"secondary"}}),_c('div',{staticClass:"text-caption mt-2"},[_vm._v("Ładowanie danych...")])],1)]:(_vm.step == 'token_error')?[_c('PpAlert',{attrs:{"title":"Błąd","type":"error","message":"Nieprawidłowy lub nieważny token"}})]:(_vm.step == 'register')?[_c('div',[_c('WkTextField',{staticClass:"mb-4",attrs:{"label":"Adres e-mail","show-asterisk":"","type":"email","disabled":"","name":"email"},model:{value:(_vm.register_email),callback:function ($$v) {_vm.register_email=$$v},expression:"register_email"}}),_c('WkTextField',{ref:"register_name",staticClass:"mb-4",attrs:{"label":"Imię i nazwisko","show-asterisk":"","rules":[
                            function (v) {
                                if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                                if (v.length > 63) { return 'Maksymalna długość pola: 63 znaki'; }
                                return true;
                            }
                        ],"name":"name"},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onRegister.apply(null, arguments)}},model:{value:(_vm.register_name),callback:function ($$v) {_vm.register_name=$$v},expression:"register_name"}}),_c('WkTextField',{ref:"register_password",staticClass:"mb-4",attrs:{"label":"Hasło","show-asterisk":"","rules":[
                            function (v) {
                                if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                                if (v.length < 8) { return 'Minimalna długość pola: 8 znaków'; }
                                if (v.length > 63) { return 'Maksymalna długość pola: 63 znaki'; }
                                if (!_vm.$store.getters['PASSWORD_VALIDATOR'](v)) {
                                    return 'Hasło musi zawierać przynajmniej 1 małą literę, 1 wielką literę i 1 cyfrę';
                                }
                                return true;
                            }
                        ],"name":"password","type":"password"},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onRegister.apply(null, arguments)}},model:{value:(_vm.register_password),callback:function ($$v) {_vm.register_password=$$v},expression:"register_password"}}),_c('WkCheckbox',{staticClass:"mt-2 mb-8",attrs:{"rules":[
                            function (v) {
                                if (!v || v !== true) {
                                    return 'Akceptacja regulaminu jest wymagana do załozenia konta w serwisie';
                                }
                                return true;
                            }
                        ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Akceptuję "),_c('a',{staticClass:"text-secondary",attrs:{"href":"https://pravna.pl/regulamin-upadlosc-konsumencka","target":"_blank"}},[_vm._v(" regulamin ")]),_vm._v(" serwisu. ")])]},proxy:true}]),model:{value:(_vm.register_terms),callback:function ($$v) {_vm.register_terms=$$v},expression:"register_terms"}}),_c('PpButton',{attrs:{"color":"secondary","block":"","loading":_vm.registering},on:{"click":_vm.onRegister}},[_vm._v(" Zarejestruj się ")]),_c('div',{staticClass:"text-center text-caption mt-4"},[_c('router-link',{staticClass:"text-primary",attrs:{"to":"/auth/logowanie"}},[_vm._v(" Posiadam już konto ")])],1)],1)]:_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }