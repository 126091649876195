var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{attrs:{"name":"Auth"}},[_c('div',[_c('h1',{staticClass:"text-ag mt-10 mb-6"},[_c('span',{staticClass:"highlighted"},[_vm._v("Odblokuj")]),_vm._v(" konto ")]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.step == 'loading')?[_c('div',{staticClass:"py-8 text-center"},[_c('PpSpinner',{attrs:{"color":"secondary","size":"32"}}),_c('div',{staticClass:"text-caption mt-2"},[_vm._v("Ładowanie danych...")])],1)]:(_vm.step == 'token_error')?[_c('PpAlert',{staticClass:"mt-10",attrs:{"type":"error","title":"Błąd","message":"Nieprawidłowy lub nieważny token"}})]:(_vm.step == 'unlock')?[_c('div',[_c('WkTextField',{ref:"unlock_email",staticClass:"mb-4",attrs:{"label":"Adres e-mail konta","show-asterisk":"","placeholder":"jan.kowalski@poczta.pl","type":"email","rules":[
                            function (v) {
                                if (!v || v == '') {
                                    return 'To pole jest wymagane';
                                }
                                if (!_vm.$store.state.REGEX_EMAIL.test(v)) {
                                    return 'Proszę podać poprawny adres e-mail';
                                }
                                return true;
                            } ],"name":"email"},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onUnlock.apply(null, arguments)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('PpButton',{attrs:{"color":"success","block":"","loading":_vm.unlocking},on:{"click":_vm.onUnlock}},[_vm._v(" Odblokuj konto ")])],1)]:_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }