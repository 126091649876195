//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PpAlert from '@/components/PpAlert';

export default {
    components: {
        PpAlert,
    },

    data() {
        return {
            step: 'loading',

            email: '',
            token: '',

            unlocking: false,
        };
    },

    methods: {
        async onUnlock() {
            if (this.unlocking) return;

            const a = [this.$refs.unlock_email.validate(true)];

            if (a.indexOf(false) !== -1) return;
            if (
                !this.token ||
                !this.$store.state.REGEX_SHA512.test(this.token)
            ) {
                return this.$message({
                    type: 'error',
                    msg: 'Podano nieprawidłowy lub nieważny token',
                });
            }

            this.unlocking = true;

            try {
                await this.$axios.$post('/auth/unlock-account', {
                    token: this.token,
                    email: this.email,
                });

                this.$message({
                    type: 'success',
                    msg: 'Konto zostało odblokowane',
                });

                this.$router.push({
                    name: 'auth-login',
                });
            } catch (err) {
                console.error(err);
            }

            this.unlocking = false;
        },
    },

    mounted() {
        if (!this.$store.state.initial_layout_set) return;
        if (
            this.$route.query.t &&
            this.$route.query.t != '' &&
            /^[a-fA-f0-9]{128}$/.test(this.$route.query.t)
        ) {
            this.token = this.$route.query.t;
            this.step = 'unlock';
        } else {
            this.step = 'token_error';
        }

        if (this.$route.query.e && this.$route.query.e != '') {
            const e = decodeURIComponent(this.$route.query.e);
            if (this.$store.state.REGEX_EMAIL.test(e)) {
                this.email = e;
            }
        }
    },

    metaInfo: {
        title: 'Odblokuj konto',
    },
};
