//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            email: "",
            loading: false
        };
    },

    methods: {
        async onFormSubmit() {
            if (this.loading || !this.$refs["email"].validate()) return;

            this.loading = true;

            try {
                await this.$axios.$post("/auth/password-reset-request", {
                    email: this.email
                });

                this.email = "";

                this.$message({
                    type: "success",
                    msg: "Jeżeli z podanym adresem e-mail jest powiązane konto w systemie PanelPomocy.pl, to zostanie na niego wysłana wiadomość umożliwiająca ustawienie nowego hasła.",
                    duration: 5000
                });

                this.email = "";
                setTimeout(_ => {
                    this.$refs["email"].resetValidation();
                }, 10);
            } catch (err) {
                console.error(err);
            }

            this.loading = false;
        }
    },

    metaInfo: {
        title: "Nie pamiętam hasła"
    }
};
