//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            login_email: "",
            login_password: "",
            loading: false,

            acc_activate_dialog: false,
            acc_activate_dialog_loading: false,
            acc_activate_dialog_code: "",
            acc_activate_dialog_email: "",

            acc_resend_code_dialog: false,
            acc_resend_code_dialog_loading: false,
            acc_resend_code_dialog_email: "",
            acc_resend_code_dialog_timer: null,
            acc_resend_code_dialog_cooldown: 300,

            acc_unlock_dialog: false,
            acc_unlock_dialog_loading: false,
            acc_unlock_dialog_email: "",
            acc_unlock_dialog_timer: null,
            acc_unlock_dialog_cooldown: 300
        };
    },

    methods: {
        async onLogin() {
            if (this.loading) return;

            const a = [
                this.$refs["login_email"].validate(),
                this.$refs["login_password"].validate()
            ];
            if (a.indexOf(false) !== -1) return;

            this.loading = true;

            try {
                const r = await this.$axios.$post("/auth/login", {
                    email: this.login_email,
                    password: this.login_password
                });

                await this.$store.dispatch("auth/logIn", r.data);

                this.$message({
                    type: "success",
                    title: "Zalogowano pomyślnie",
                    msg: "Witaj ponownie, " + this.$store.getters["auth/userFirstName"],
                    duration: 5000
                });

                if (this.$route.query.rback && this.$route.query.rback != "") {
                    this.$router.push(decodeURIComponent(this.$route.query.rback));
                } else {
                    this.$router.push("/");
                }
            } catch (err) {
                console.error(err);
                if (err && err.data) {
                    if (err.data.error_code == 202) {
                        return this.$router.push({
                            name: "auth-register",
                            query: {
                                token: err.data.acc_create_token
                            }
                        });
                    } else if (err.data.error_code == 203) {
                        this.acc_activate_dialog = true;
                        this.acc_activate_dialog_email = this.acc_resend_code_dialog_email =
                            this.login_email;
                    } else if (err.data.error_code == 204) {
                        this.acc_unlock_dialog = true;
                        this.acc_unlock_dialog_email = this.login_email;
                    }
                }
            }

            this.loading = false;
        },

        async onActivate() {
            if (this.acc_activate_dialog_loading) return;

            const a = [this.$refs["activate_email"].validate(true)];
            if (a.indexOf(false) !== -1) return;

            if (!/^[1-9][0-9]{5}$/.test(this.acc_activate_dialog_code)) {
                return this.$message({
                    type: "error",
                    msg: "Uzupełnij kod aktywacyjny"
                });
            }

            this.acc_activate_dialog_loading = true;

            try {
                await this.$axios.$post("/auth/activate", {
                    otp: this.acc_activate_dialog_code,
                    email: this.acc_activate_dialog_email
                });

                this.$message({
                    type: "success",
                    msg: "Twoje konto zostało aktywowane"
                });
                this.acc_activate_dialog_email = "";
                this.acc_activate_dialog_code = "";
                this.$refs["activate_email"].resetValidation();
                this.acc_activate_dialog = false;

                this.onLogin();
            } catch (err) {
                console.error(err);
            }

            this.acc_activate_dialog_loading = false;
        },

        async onAccCodeResend() {
            if (
                this.acc_resend_code_dialog_loading ||
                !this.$refs["activate_resend_email"].validate(true)
            )
                return;
            this.acc_resend_code_dialog_loading = true;

            try {
                await this.$axios.$post("/auth/resend-activation-code", {
                    email: this.acc_resend_code_dialog_email
                });

                this.$message({
                    type: "success",
                    msg: "Wiadomość została wysłana"
                });

                this.acc_resend_code_dialog_email = "";
                this.$refs["activate_resend_email"].resetValidation();
                this.acc_resend_code_dialog = false;
                this.acc_resend_code_dialog_timer = setInterval(this.accCooldownTimer, 1000);
            } catch (err) {
                console.error(err);
            }

            this.acc_resend_code_dialog_loading = false;
        },
        accCooldownTimer() {
            if (this.acc_resend_code_dialog_cooldown == 0) {
                clearInterval(this.acc_resend_code_dialog_timer);
                this.acc_resend_code_dialog_timer = null;
                this.acc_resend_code_dialog_cooldown = 300;
            } else {
                this.acc_resend_code_dialog_cooldown -= 1;
            }
        },

        async onAccUnlockResend() {
            if (this.acc_unlock_dialog_loading || !this.$refs["unlock_resend_email"].validate(true))
                return;
            this.acc_unlock_dialog_loading = true;

            try {
                await this.$axios.$post("/auth/unlock-account-request", {
                    email: this.acc_unlock_dialog_email
                });

                this.$message({
                    type: "success",
                    msg: "Wiadomość została wysłana"
                });

                this.acc_unlock_dialog_email = "";
                this.$refs["unlock_resend_email"].resetValidation();
                this.acc_unlock_dialog = false;
                this.acc_unlock_dialog_timer = setInterval(this.accUnlockCooldownTimer, 1000);
            } catch (err) {
                console.error(err);
            }

            this.acc_unlock_dialog_loading = false;
        },
        accUnlockCooldownTimer() {
            if (this.acc_unlock_dialog_cooldown == 0) {
                clearInterval(this.acc_unlock_dialog_timer);
                this.acc_unlock_dialog_timer = null;
                this.acc_unlock_dialog_cooldown = 300;
            } else {
                this.acc_unlock_dialog_cooldown -= 1;
            }
        }
    },

    beforeDestroy() {
        if (this.acc_resend_code_dialog_timer != null) {
            clearInterval(this.acc_resend_code_dialog_timer);
        }
        if (this.acc_unlock_dialog_timer != null) {
            clearInterval(this.acc_unlock_dialog_timer);
        }
    },

    metaInfo: {
        title: "Zaloguj się"
    }
};
