//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PpAlert from '@/components/PpAlert';
import PpSpinner from '@/components/PpSpinner';

export default {
    components: {
        PpAlert,
        PpSpinner,
    },

    data() {
        return {
            step: 'loading',

            email: '',
            token: '',
            password: '',
            password_repeat: '',

            loading: false,
        };
    },

    methods: {
        async onFormSubmit() {
            if (this.loading) return;

            const a = [
                this.$refs['password'].validate(),
                this.$refs['password-repeat'].validate(),
            ];
            if (a.indexOf(false) !== -1) return;

            this.loading = true;

            try {
                await this.$axios.$post('/auth/password-reset', {
                    token: this.token,
                    email: this.email,
                    password: this.password,
                });

                this.$message({
                    type: 'success',
                    msg: 'Hasło zostało zmienione. Możesz się teraz zalogować.',
                    duration: 6000,
                });

                this.$router.push({ name: 'auth-login' });
            } catch (err) {
                console.error(err);
            }

            this.loading = false;
        },
    },

    created() {
        if (!this.$store.state.initial_layout_set) return;
        if (
            this.$route.query.t &&
            this.$route.query.t != '' &&
            this.$store.state.REGEX_SHA512.test(this.$route.query.t)
        ) {
            this.token = this.$route.query.t;
            this.step = 'submit';
        } else {
            this.step = 'token_error';
        }

        if (this.$route.query.e && this.$route.query.e != '') {
            const e = decodeURIComponent(this.$route.query.e);
            if (this.$store.state.REGEX_EMAIL.test(e)) {
                this.email = e;
            }
        }
    },

    metaInfo: {
        title: 'Ustaw nowe hasło',
    },
};
