var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{attrs:{"name":"Auth"}},[_c('div',[_c('h1',{staticClass:"text-ag mb-6 mt-10"},[_vm._v(" Nie pamiętam "),_c('span',{staticClass:"highlighted"},[_vm._v("hasła")])]),_c('p',{staticClass:"mb-10 mt-4 text-h6",staticStyle:{"line-height":"1.5"}},[_vm._v(" Wpisz poniżej adres e-mail powiązany z Twoim kontem w PanelPomocy.pl, aby otrzymać wiadomość umożliwiającą zresetowanie hasła. ")]),_c('WkTextField',{ref:"email",attrs:{"label":"Adres e-mail","show-asterisk":"","placeholder":"j.kowalski@poczta.pl","type":"email","rules":[
                function (v) {
                    if (!v || v == '') {
                        return 'To pole jest wymagane';
                    }
                    if (!_vm.$store.state.REGEX_EMAIL.test(v)) {
                        return 'Proszę podać poprawny adres e-mail';
                    }
                    return true;
                }
            ],"name":"email"},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onFormSubmit.apply(null, arguments)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('PpButton',{staticClass:"mt-8",attrs:{"color":"secondary","loading":_vm.loading,"block":""},on:{"click":_vm.onFormSubmit}},[_vm._v(" Resetuj hasło ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }