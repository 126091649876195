var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{attrs:{"name":"Auth"}},[_c('div',[_c('h1',{staticClass:"mt-10 mb-8 text-ag"},[_vm._v("Zmień swoje hasło")]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.step == 'submit')?_c('div',[_c('WkTextField',{ref:"password",staticClass:"mb-4",attrs:{"label":"Nowe hasło","show-asterisk":"","type":"password","rules":[
                        function (v) {
                            if (!v || v == '') {
                                return 'To pole jest wymagane';
                            }
                            if (!_vm.$store.getters['PASSWORD_VALIDATOR'](v)) {
                                return 'Hasło powinno mieć 8-63 znaków długości oraz składać się z przynajmniej 1 małej litery, 1 wielkiej litery i 1 cyfry';
                            }
                            return true;
                        } ],"name":"password"},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onFormSubmit.apply(null, arguments)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('WkTextField',{ref:"password-repeat",attrs:{"label":"Powtórz nowe hasło","show-asterisk":"","type":"password","rules":[
                        function (v) {
                            if (!v || v == '') {
                                return 'To pole jest wymagane';
                            }
                            if (v != this$1.password) {
                                return 'Podane hasła różnią się';
                            }
                            return true;
                        } ],"name":"password-repeat"},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onFormSubmit.apply(null, arguments)}},model:{value:(_vm.password_repeat),callback:function ($$v) {_vm.password_repeat=$$v},expression:"password_repeat"}}),_c('PpButton',{staticClass:"mt-8",attrs:{"color":"secondary","block":"","loading":_vm.loading},on:{"click":_vm.onFormSubmit}},[_vm._v(" Zmień hasło ")])],1):(_vm.step == 'token_error')?_c('div',[_c('PpAlert',{attrs:{"type":"error","title":"Błędne dane","message":"Podano nieprawidłowy lub nieważny token"}})],1):_c('div',{staticClass:"bg-primary bg-primary--light px-4 py-10 text-center"},[_c('PpSpinner',{attrs:{"color":"primary","size":"36"}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }