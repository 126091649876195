//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PpAlert from "@/components/PpAlert";

export default {
    components: {
        PpAlert
    },

    data() {
        return {
            step: "loading",

            register_email: "",
            register_name: "",
            register_password: "",
            register_terms: false,

            registering: false
        };
    },

    methods: {
        async onRegister() {
            if (this.registering) return;

            const a = [
                this.$refs.register_name.validate(true),
                this.$refs.register_password.validate(true),
                this.register_terms === true
            ];

            if (a.indexOf(false) !== -1) return;

            this.registering = true;

            try {
                const r = await this.$axios.$post("/auth/register", {
                    token: this.$route.query.token,
                    name: this.register_name,
                    password: this.register_password
                });

                this.$message({
                    type: "success",
                    msg: "Konto zostało utworzone"
                });

                await this.$store.dispatch("auth/logIn", r.data);

                setTimeout(_ => {
                    this.$router.push({
                        name: "home"
                    });
                }, 10);
            } catch (err) {
                console.error(err);
            }

            this.registering = false;
        },

        async fetchUserData() {
            try {
                const r = await this.$axios.$get(
                    "/users/me/?access_token=" + this.$route.query.token,
                    {
                        supress_errors: true
                    }
                );

                this.register_email = r.user.email;
                this.register_name = r.user.name;
                this.step = "register";
            } catch (err) {
                // console.error(err);
                if (err && err.response && err.response.status == 401) {
                    this.$message({
                        type: "info",
                        title: "Rejestracja ukończona",
                        msg: "Twoje konto zostało już aktywowane, zaloguj się aby uzyskać dostęp do serwisu."
                    });
                    this.$router.push({
                        name: "auth-login"
                    });
                } else {
                    this.step = "token_error";
                }
            }
        }
    },

    mounted() {
        if (!this.$store.state.initial_layout_set) return;
        if (this.$store.getters["auth/isLoggedIn"]) {
            return this.$router.push({
                name: "home"
            });
        }
        if (
            this.$route.query.token &&
            this.$route.query.token != "" &&
            this.$store.state.REGEX_SHA512.test(this.$route.query.token)
        ) {
            this.fetchUserData();
        } else {
            this.step = "token_error";
        }
    },

    metaInfo: {
        title: "Rejestracja"
    }
};
